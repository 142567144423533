<template>
  <div class="chart-box" :class="{ 'chart-heigth': chartType == 'Type' }">
    <div class="chart-body">
      <mdb-doughnut-chart
        class="chart"
        ref="chartRef"
        :data="doughnutChartData"
        :options="doughnutChartOptions"
        :width="width"
        :height="height"
      ></mdb-doughnut-chart>
    </div>
    <div
      class="chart-label"
      :class="{ 'chart-label-position': chartType == 'Anomalie' }"
    >
      <div
        v-for="(lab, index) in labelTable"
        :key="lab.id"
        :class="{ boxclick: lab.show }"
        @click="onClickEvent(lab)"
      >
        <b-button
          :title="lab.item"
          :style="'background-color:' + datasets.backgroundColor[index]"
        >
          <span v-if="lab.item !== 'Tous' && data.length != 0">{{
            data[index]
          }}</span>
          <span v-if="lab.item === 'Tous' && data.length != 0">{{
            data[1] + data[2]
          }}</span> </b-button
        ><span class="ml-2">{{ lab.item | LabelChartFormat }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbDoughnutChart } from 'mdbvue';
import 'chartjs-plugin-labels';
export default {
  props: {
    width: {
      type: Number
    },
    height: {
      type: Number
    },
    label: {
      type: Array
    },
    data: {
      type: Array
    },
    datasets: {
      type: Object
    },
    type: {
      default: null
    },
    chartType: {
      default: null
    },
    changeType: {
      type: Boolean
    },
    changeYearOrWeek: {
      type: Boolean
    }
  },
  data() {
    return {
      labelTable: [],
      doughnutChartData: {
        labels: this.label,
        datasets: [
          {
            data: this.data,
            backgroundColor: this.datasets.backgroundColor,
            hoverBackgroundColor: this.datasets.hoverBackgroundColor
          }
        ]
      },
      doughnutChartOptions: {
        responsive: false,
        legend: {
          display: false
        },
        plugins: {
          labels: {
            render: 'percentage',
            showActualPercentages: true,
            fontColor: 'white',
            fontSize: 10
          }
        }
      }
    };
  },
  methods: {
    onClickEvent(legendItem) {
      if (this.chartType == 'Type') {
        if (legendItem.item == 'Tous') {
          this.labelTable[1].show = false;
          this.labelTable[2].show = false;
        } else if (legendItem.item == 'Invalide') {
          this.labelTable[0].show = false;
          this.labelTable[2].show = false;
        } else {
          this.labelTable[0].show = false;
          this.labelTable[1].show = false;
        }
      }
      legendItem.show = !legendItem.show;
      this.$emit('selected', legendItem.item);
    }
  },
  components: {
    mdbDoughnutChart
  },
  watch: {
    changeYearOrWeek: function() {
      this.labelTable = [];
      this.labelTable = this.label.map(item => {
        return {
          item,
          show: false
        };
      });
      if (this.chartType == 'Type') {
        this.labelTable[0].show = true;
      }
    },
    label: function() {
      if (this.labelTable.length == 0) {
        this.labelTable = this.label.map(item => {
          return {
            item,
            show: false
          };
        });
      }
      if (this.chartType == 'Type') {
        if (this.type == null) {
          this.labelTable[0].show = true;
        } else {
          this.labelTable.map(item => {
            if (item.item == this.type) {
              item.show = true;
            } else {
              item.show = false;
            }
          });
        }
      } else {
        if (this.type.length != 0) {
          this.labelTable.map(item => {
            if (this.type.find(anomalie => anomalie === item.item)) {
              item.show = true;
            }
          });
        }
      }
    },
    data: function() {
      this.doughnutChartData.labels = this.label;
      this.doughnutChartData.datasets[0]['data'] = this.data;
      this.$nextTick(() => {
        this.$refs['chartRef'].renderChart(
          this.doughnutChartData,
          this.doughnutChartOptions
        );
      });
    },
    changeType: function() {
      this.labelTable[0].show = false;
      this.labelTable[1].show = true;
      this.labelTable[2].show = false;
    },
    type: function() {
      if (this.chartType == 'Type') {
        if (this.type == null) {
          this.labelTable[0].show = true;
        } else {
          this.labelTable.map(item => {
            if (item.item == this.type) {
              item.show = true;
            } else {
              item.show = false;
            }
          });
        }
      } else {
        if (this.type && this.type.length != 0) {
          this.labelTable.map(item => {
            if (this.type.find(anomalie => anomalie === item.item)) {
              item.show = true;
            }
          });
        }
      }
    }
  },
  filters: {
    LabelChartFormat: value => {
      switch (value) {
        case 'regie':
          return 'Regie non trouvé';
        case 'previsiteur':
          return 'Prévisiteur non trouvé';
        case 'confirmateur':
          return 'Confirmateur non trouvé';
        case 'administrateur':
          return 'Administrateur non trouvé';
        case 'poseur':
          return 'Poseur non trouvé';
        case 'coposeurs':
          return 'Coposeurs non trouvé';
        case 'depot_stockage':
          return 'Depot non trouvé';
        case 'responsable_zone':
          return 'Responsable zone  non trouvé';
        case 'surface_101':
          return 'Total surface 101 est incorrect';
        case 'surface_102':
          return 'Total surface 102 est incorrect';
        case 'surface_103':
          return 'Total surface 103 est incorrect';
        case 'total_surface_a_isoler':
          return 'Total surface a isoler est incorrect';
        case 'agent_commercial_terrain':
          return 'Agent commercial terrain non trouvé';
        case 'invalides':
          return 'Invalides';
        case 'valides':
          return 'Valide';
        case 'agent_confirmateur':
          return 'Agent Confirmateur';
        case 'agent_commercial':
          return 'Agent Commercial non trouvé';
        default:
          return value;
      }
    }
  },
  mounted() {
    this.labelTable = this.label.map(item => {
      return {
        item,
        show: false
      };
    });
  }
};
</script>
<style lang="scss">
.chart-body {
  .chart {
    canvas {
      height: 147px !important;
    }
  }
}
</style>
<style scoped lang="scss">
.chart-heigth {
  height: 100% !important;
}
.chart-box {
  display: block;
  width: 100%;
  height: calc(100vh - 168px);
  .boxclick {
    border: 1px solid #88878742;
    box-shadow: 0px 3px 6px #aaaaaa42;
    cursor: pointer;
  }
  .chart-label-position {
    align-items: initial !important;
    flex-direction: row !important;
  }
  .chart-label {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: center;
    overflow-x: auto;
    max-height: calc(100% - 172px);
    div {
      background-color: #f6f5fb;
      border-radius: 27px;
      min-width: 70%;
      margin: 3px;
      padding: 3px;
      cursor: pointer;
      display: flex;
      align-items: center;
      button {
        width: 50px;
        height: 21px;
        border: none;
        margin-left: 2px;
        font-size: 9px;
        padding: 1px;
        border-radius: 19px;
        color: #fff;
        &:focus,
        &.focus {
          box-shadow: none;
        }
        span {
          color: #fff;
          padding: 0px;
          margin: 0px;
          font-size: 9px;
          font-family: 'Montserrat', sans-serif;
        }
      }

      span {
        color: #5d5d5d;
        padding: 0px;
        margin: 0px;
        font-size: 9px;
        font-family: 'Montserrat', sans-serif;
      }
    }
  }
}
@media screen and (max-width: 1035px) {
  .chart-box {
    .chart-label {
      padding: 7px 0px;
    }
  }
}
@media screen and (max-width: 768px) {
  .chart-box {
    width: 58%;
    .chart-label {
      width: 50%;
      padding: 0px;
      div {
        width: 50%;
        button {
          width: 26px;
          height: 13.5px;
          border: none;
          margin-left: 4px;
          &:focus,
          &.focus {
            box-shadow: none;
          }
        }
        span {
          font-size: 8px;
        }
      }
    }
  }
}
@media screen and (max-width: 475px) {
  .chart-box {
    .chart-label {
      width: 100%;
      height: 152px;
    }
  }
}
@media screen and (max-width: 375px) {
  .chart-box {
    .chart-label {
      height: 222px;
    }
  }
}

@media screen and (max-width: 320px) {
  .chart-box {
    .chart-label {
      height: 244px;
    }
  }
}
</style>
